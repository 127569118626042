<!-- Inner Banner -->
<div class="inner-banner aboutus-bg">
    <div class="container">
        <div class="inner-title text-center">
            <h3>RightTalents have great credentilas with <b>10+</b> Years of expertise in IT staffing.</h3>
            <!--ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>About Us</li>
            </ul-->
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- About Area -->
<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 nomobile">
                <div class="about-img about-img-before">
                    <img style="border-radius: 10px" src="assets/img/about/dreamstime_s_113677669.jpg" alt="Images">
                    <div class="about-img-small ">
                        <div class="badge-base LI-profile-badge" data-version="v1" data-size="large" data-locale="en_US" data-type="VERTICAL" data-theme="dark" data-vanity="ppalani">
                            <a class="LI-simple-link" href='https://www.linkedin.com/in/ppalani?trk=profile-badge'></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span>About Us</span>
                    <!--h2>We have great credentilas with <b>10+</b> Years of expertise in IT staffing.</h2-->
                    <h2>We are an IT Services Company and provide the staffing, consulting and training services. </h2>
                    <p>Pon Palani is the President and CEO of the company. He is an Industry veteran with more than 20 years of experience.</p>

                    <!--div style="padding-left: 20px">
                        <ul class="aboutus">
                            <ol><a href="https://www.linkedin.com/in/ppalani/">Ponnusamy Palanimuthu</a> – President & CEO</ol>
                        </ul>
                    </div-->
                    <div class="badge-base LI-profile-badge onlymobile" data-version="v1" data-size="large" data-locale="en_US" data-type="VERTICAL" data-theme="dark" data-vanity="ppalani">
                        <a class="LI-simple-link" href='https://www.linkedin.com/in/ppalani?trk=profile-badge'></a>
                    </div>

                    <div class="row">
                        <div class="col-lg-4 col-md-4 pr-0">
                            <div class="about-counter">
                                <i class="flaticon-medal"></i>
                                <div class="content">
                                    <h3>4+</h3>
                                    <span>Ceritifications</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-8 col-md-8 pr-0">
                            <div class="about-counter">
                                <i class="flaticon-team"></i>
                                <div class="content">
                                    <h3>10+</h3>
                                    <span>Years of Expertise in IT Staffing</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--div class="about-btn">
                        <a routerLink="/contact" class="get-btn">Get A Quote</a>
                    </div-->
                </div>
            </div>
        </div>
    </div>
    <div class="about-polygon-shape">
        <img src="assets/img/shape/shape10.png" alt="Images">
    </div>
</div>
<!-- About Area End -->

<!-- Choose Area -->
<div class="choose-area-two choose-bg pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-9 col-md-10 col-sm-12">
                <div class="choose-content choose-content-title">
                    <div class="section-title">
                        <h2>Diversity</h2>
                        <p>RightTalents believes in diversity for inclusive, sustainable growth and development. <b>Certified as Minority Business Enterprise</b> by the following organizations.</p>
                    </div>

                    <div class="row border-top rightshift">
                        <div class="col-lg-6 col-sm-6">
                            <div class="choose-content-list">
                                <i class="flaticon-medal"></i>
                                <div class="content">
                                    <h3>MBE by NMSDC</h3>
                                    <p>Certified MBE by National Minority Supplier Development Council (NMSDC)</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="choose-content-list">
                                <i class="flaticon-medal"></i>
                                <div class="content">
                                    <h3>MBE by PANYNJ</h3>
                                    <p>Certified Minority Business Enterprise by Port Authority of NY and NJ</p>
                                </div>
                            </div>
                        </div>


                        <div class="col-lg-6 col-sm-6">
                            <div class="choose-content-list">
                                <i class="flaticon-medal"></i>
                                <div class="content">
                                    <h3>MBE by NYC</h3>
                                    <p>Certified Minority Business Enterprise by NewYork City Small Business Services</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <div class="choose-content-list">
                                <i class="flaticon-medal"></i>
                                <div class="content">
                                    <h3>CompTIA</h3>
                                    <p>Certified as CompTIA Solution Provider Member</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <div class="choose-content-list">
                                <i class="flaticon-medal"></i>
                                <div class="content">
                                    <h3>Recognised by NMSDC</h3>
                                    <p>Featured in NMSDC’s Fast 50: Top Growing MBEs List 2022</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-2 col-sm-12 certspace">
                <div class="choose-img-2  imgframe">
                    <div class="certframe">
                        <img src="assets/img/about/image005.jpg" class="certholder darkblueshadow" alt="Images">
                    </div>
                    <div class="certframe">
                        <img class="certholder"  src="assets/img/fast50logo.jpg">
                    </div>
                    <div class="certframe">
                        <img class="certholder"  src="assets/img/NMSDC_CERIFIED_2024.jpg">
                    </div>
                    <!--img src="assets/img/about/ASA_Logo.jpg"  class="certholder" alt="Images"-->
                    <!--div class="certframe">
                        <img src="assets/img/about/nmsdc_certified.jpeg" class="certholder" alt="Images">
                    </div-->
                    <div class="certframe">
                        <img src="assets/img/about/nycsmallbusiness.png" class="certholder" alt="Images">
                    </div>
                    <div class="certframe">
                        <img src="assets/img/about/comptia-proud-member-logo.jpg" class="certholder" alt="Images">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Choose Area End -->

<!-- Client Area -->
<!--div class="client-area-two ptb-100">
    <div class="container">
        <div class="section-title text-center">
            <span>Clients Review</span>
            <h2>Our Lovely Clients Get Helped From Our Company</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla et amet bibendum ante. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae pellentesque.</p>
        </div>

        <div class="client-slider owl-carousel owl-theme pt-45">
            <div class="client-card">
                <h3>Thomas Smith</h3>
                <span>CEO Of LTD Company</span>
                <ul>
                    <li>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                    </li>
                </ul>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque non ullamcorper tortor, vel mattis mauris. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                <i class="flaticon-quote client-card-quote"></i>
                <div class="client-card-img">
                    <img src="assets/img/client/client-img1.png" alt="Images">
                </div>
            </div>

            <div class="client-card">
                <h3>Sinthy Alina</h3>
                <span>CEO Of Gloz</span>
                <ul>
                    <li>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                    </li>
                </ul>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque non ullamcorper tortor, vel mattis mauris. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                <i class="flaticon-quote client-card-quote"></i>
                <div class="client-card-img">
                    <img src="assets/img/client/client-img4.png" alt="Images">
                </div>
            </div>

            <div class="client-card">
                <h3>James Nilson</h3>
                <span>Manager Of Gloz</span>
                <ul>
                    <li>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                    </li>
                </ul>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque non ullamcorper tortor, vel mattis mauris. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                <i class="flaticon-quote client-card-quote"></i>
                <div class="client-card-img">
                    <img src="assets/img/client/client-img3.png" alt="Images">
                </div>
            </div>
        </div>
    </div>

    <div class="client-shape">
        <div class="shape1"><img src="assets/img/shape/shape8.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape6.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape11.png" alt="Images"></div>
    </div>
</div-->
<!-- Client Area End -->